import { BackendMethod } from '@memberspot/shared/model/backend';
import { CustomPropertiesData } from '@memberspot/shared/model/custom-user-property';

import { UserMailSubscription } from '../models/update-user-mail-subscription.model';
import { ChapterAccessDto } from './dtos/chapter-access.dto';
import { DeleteMultipleUsersData } from './dtos/delete-users.dto';
import type {
  GrantMultipleUsersOfferDto,
  GrantUserOfferDto,
  UpdateOrderIdDto,
  UpdateUserAccessDto,
  UpdateUserAccessExpiryDto,
  UserHasOfferSelect,
} from './dtos/grant-offer.dto';
import { SearchUserDto } from './dtos/search-users.dto';
import {
  SchoolUserSettingsDto,
  SetModeratorDto,
} from './dtos/set-moderator.dto';
import { UpdateStartDateDto } from './dtos/update-start-date.dto';
import { RemoveDeviceAdminDto } from './dtos/user-device.dto';
import { AddUsersDto, ImportUsersDto } from './dtos/users.dto';
import { VerifyMailDto } from './dtos/verify-mail.dto';

const SchoolUserEndpointsInternal = {
  SEARCH: (schoolId: string, data: SearchUserDto) => ({
    url: `/users/search/${schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  Verify: (schoolId: string, data: VerifyMailDto) => ({
    url: `/users/verify/${schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  EXPORT: (schoolId: string, data: SearchUserDto) => ({
    url: `/users/export/${schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  RESEND_WELCOME_MESSAGES: (schoolId: string) => ({
    url: `/users/resend-welcome-messages/${schoolId}`,
    method: BackendMethod.POST,
  }),
  DELETE_USER: (schoolId: string, uid: string) => ({
    url: `/users/delete/${schoolId}/${uid}`,
    method: BackendMethod.DELETE,
  }),
  DELETE_MULTIPLE_USERS: (schoolId: string, data: DeleteMultipleUsersData) => ({
    url: `/users/delete-multiple/${schoolId}`,
    method: BackendMethod.POST,
    data,
  }),
  REMOVE_DEVICE: (schoolId: string, data: RemoveDeviceAdminDto) => ({
    url: `/users/remove-device/${schoolId}`,
    method: BackendMethod.PATCH,
    data,
  }),
  CHAPTER_ACCESS: (schoolId: string, data: ChapterAccessDto) => ({
    url: `/users/chapter-access/${schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  UPDATE_USER: (schoolId: string, uid: string) =>
    `/users/update-profile/${schoolId}/${uid}`,
  IMPORT_USERS: (data: ImportUsersDto) => ({
    url: '/users/import-users',
    data,
  }),
  ADD_USERS: (data: AddUsersDto) => ({
    url: `/users/add-users`,
    data,
    method: BackendMethod.POST,
  }),
  GRANT_USER_OFFER: (data: GrantUserOfferDto) => ({
    url: '/users/grant-offer',
    data,
    method: BackendMethod.POST,
  }),
  GRANT_MULTIPLE_USERS_OFFER: (data: GrantMultipleUsersOfferDto) => ({
    url: '/users/grant-multiple-users-offer',
    data,
    method: BackendMethod.POST,
  }),
  UPDATE_USER_ACCESS: (data: UpdateUserAccessDto) => ({
    url: '/users/update-user-access',
    data,
    method: BackendMethod.POST,
  }),
  UPDATE_USER_ACCESS_EXPIRY: (data: UpdateUserAccessExpiryDto) => ({
    url: '/users/update-user-access-expiry',
    data,
    method: BackendMethod.POST,
  }),
  REMOVE_OFFER_FOR_USERS: (
    schoolId: string,
    offerId: string,
    data: UserHasOfferSelect[],
    sendMessage: boolean,
  ) => ({
    url: `/users/remove-users-access/${schoolId}/${offerId}`,
    data: {
      data,
      sendMessage,
    },
    method: BackendMethod.POST,
  }),
  UPDATE_OFFERID: (data: UpdateOrderIdDto) => ({
    url: '/users/update-orderid',
    data,
    method: BackendMethod.POST,
  }),
  UPDATE_START_DATE: (data: UpdateStartDateDto) => ({
    url: '/users/update-start-date',
    data,
    method: BackendMethod.POST,
  }),
  DOES_USER_EXIST: (schoolId: string, data: VerifyMailDto) => ({
    url: `/users/does-user-exist/${schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  UPDATE_MAIL_SUBSCRIPTION: (
    schoolId: string,
    uid: string,
    data: UserMailSubscription,
  ) => ({
    url: `/users/unsubscribe-mails/${schoolId}/${uid}`,
    data,
    method: BackendMethod.POST,
  }),
  GET_SCHOOL_USER: (schoolId: string, uid: string) => ({
    url: `/users/${schoolId}/${uid}`,
    method: BackendMethod.GET,
  }),
  RESEND_WELCOME_MESSAGE: '/user-messages/resend-welcome',
  GET_LAST_SENT_MESSAGES: '/user-messages/last',
  SET_MODERATED_SPACES: (data: SetModeratorDto) => ({
    url: `/users/set-moderated-spaces`,
    data,
    method: BackendMethod.POST,
  }),
  SET_SCHOOL_USER_SETTINGS: (data: SchoolUserSettingsDto) => ({
    url: `/users/set-user-settings`,
    data,
    method: BackendMethod.PATCH,
  }),
  SET_CUSTOM_PROPERTIES: (
    schoolId: string,
    uid: string,
    data: CustomPropertiesData,
  ) => ({
    url: `/users/set-custom-properties/${schoolId}/${uid}`,
    data,
    method: BackendMethod.PATCH,
  }),
  SET_USER_ORGANISATION: (
    schoolId: string,
    uid: string,
    organisationId: string,
  ) => ({
    url: `/users/set-organisation/${schoolId}/${uid}`,
    data: { organisationId },
    method: BackendMethod.POST,
  }),
  SET_ORGANISATION_FOR_MULTIPLE_USERS: (
    schoolId: string,
    uids: string[],
    organisationId: string | null,
  ) => ({
    url: `/users/set-organisation/${schoolId}`,
    data: { uids, organisationId },
    method: BackendMethod.POST,
  }),
  GET_LAST_LOGGED_IN_USERS: (
    schoolId: string,
    pageSize?: number,
    lastLoadedDate?: Date,
  ) => ({
    url: addQueryParamsToQuery(
      `/users/last-logged-in/${schoolId}`,
      pageSize,
      lastLoadedDate,
    ),
    method: BackendMethod.GET,
  }),
  GET_ACTIVE_MEMBERS_COUNT: (schoolId: string) => ({
    url: `/users/active-members-count/${schoolId}`,
    method: BackendMethod.GET,
  }),
};

export const SchoolUserEventEndpoints = {
  GET_EVENTS_PAGE: (
    schoolId: string,
    pageSize?: number,
    lastLoadedId?: string,
  ) => ({
    url: addQueryParamsToQuery(
      `/users/events/${schoolId}`,
      pageSize,
      undefined,
      lastLoadedId,
    ),
    method: BackendMethod.GET,
  }),
};

const addQueryParamsToQuery = (
  baseUrl: string,
  pageSize?: number,
  lastLoadedDate?: Date,
  lastLoadedId?: string,
) => {
  let url = baseUrl;

  const params: Record<string, string> = {};

  if (pageSize) {
    params['pageSize'] = pageSize.toString();
  }

  if (lastLoadedId) {
    params['lastLoadedId'] = lastLoadedId;
  }

  if (lastLoadedDate) {
    params['lastLoadedDate'] = lastLoadedDate.toISOString();
  }

  if (Object.keys(params).length > 0) {
    url += '?' + new URLSearchParams(params).toString();
  }

  return url;
};

export const SchoolUserEndpoints: Readonly<typeof SchoolUserEndpointsInternal> =
  SchoolUserEndpointsInternal;
